
import React from "react";

export const RoadMap = () => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
      <h1 className="setion_title">ROADMAP</h1>
      <img src="img/about_line.svg" className="line justify-content-center mx-auto" style={{maxWidth:'100%'}}></img>
          <section class="timeline">
            <ol>
              <li>
                <div>
                  <time>Presale and Marketing</time> Dogecoin20 starts on a level playing field with a fair presale. The team will allocate a portion of funds raised towards a professional marketing strategy.
                </div>
              </li>
              <li>
                <div>
                  <time>Early Staking Incentive</time> Presale buyers of DOGE20 will be able to stake their tokens into the smart contract before listing day to benefit from the high early rewards opportunity.
                </div>
              </li>
              <li>
                <div>
                  <time>Presale Token Claiming</time> The DOGE20 staking smart contract is programmed to distribute tokens to all stakers in the pool over a period of two years, providing sustained passive rewards.
                </div>
              </li>
              <li>
                <div>
                  <time>DEX Launch on Uniswap</time> DOGE20 will launch on Uniswap, the largest decentralized exchange. This will provide the best trading environment and early liquidity.
                </div>
              </li>
              <li>
                <div>
                  <time>Allocated Liquidity Provision</time> The Dogecoin20 team will add a substantial liquidity pool to Uniswap. For this purpose, 10% of the total token supply has been set aside.
                </div>
              </li>
              <li>
                <div>
                  <time>Long-Term Passive Rewards</time> The DOGE20 staking smart contract is programmed to distribute tokens to all stakers in the pool over a period of two years, providing sustained passive rewards.
                </div>
              </li>
              <li></li>
            </ol>
          </section>
      </div>
    </div>
  );
};
